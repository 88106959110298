import * as Types from '@/types/gqlCommon.delio.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PromotionsQueryVariables = Types.Exact<{
  coordinates?: Types.InputMaybe<Types.CoordinatesInput>;
}>;


export type PromotionsQuery = { readonly __typename: 'Query', readonly webHomeFeedPromotionSection: { readonly __typename: 'WebHomeFeedPromotionSection', readonly section?: { readonly __typename: 'PromotionsSection', readonly id: string, readonly title: string, readonly promotions: ReadonlyArray<{ readonly __typename: 'Promotion', readonly backgroundColor: string, readonly iconUrl: string, readonly id: string, readonly key: string, readonly title: string, readonly promotions: ReadonlyArray<{ readonly __typename: 'Promotion', readonly backgroundColor: string, readonly iconUrl: string, readonly id: string, readonly key: string, readonly title: string, readonly webImage: { readonly __typename: 'WebPromotionImage', readonly desktopImageUrl: string, readonly mobileImageUrl: string } }>, readonly webImage: { readonly __typename: 'WebPromotionImage', readonly desktopImageUrl: string, readonly mobileImageUrl: string } }> } | null } };


export const PromotionsDocument = gql`
    query Promotions($coordinates: CoordinatesInput) {
  webHomeFeedPromotionSection(coordinates: $coordinates) {
    section {
      id
      promotions {
        promotions {
          backgroundColor
          iconUrl
          id
          key
          title
          webImage {
            desktopImageUrl
            mobileImageUrl
          }
        }
        backgroundColor
        iconUrl
        id
        key
        title
        webImage {
          desktopImageUrl
          mobileImageUrl
        }
      }
      title
    }
  }
}
    `;

/**
 * __usePromotionsQuery__
 *
 * To run a query within a React component, call `usePromotionsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePromotionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePromotionsQuery({
 *   variables: {
 *      coordinates: // value for 'coordinates'
 *   },
 * });
 */
export function usePromotionsQuery(baseOptions?: Apollo.QueryHookOptions<PromotionsQuery, PromotionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PromotionsQuery, PromotionsQueryVariables>(PromotionsDocument, options);
      }
export function usePromotionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PromotionsQuery, PromotionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PromotionsQuery, PromotionsQueryVariables>(PromotionsDocument, options);
        }
export function usePromotionsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<PromotionsQuery, PromotionsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PromotionsQuery, PromotionsQueryVariables>(PromotionsDocument, options);
        }
export type PromotionsQueryHookResult = ReturnType<typeof usePromotionsQuery>;
export type PromotionsLazyQueryHookResult = ReturnType<typeof usePromotionsLazyQuery>;
export type PromotionsSuspenseQueryHookResult = ReturnType<typeof usePromotionsSuspenseQuery>;
export type PromotionsQueryResult = Apollo.QueryResult<PromotionsQuery, PromotionsQueryVariables>;